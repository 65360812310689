<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
// 1. imports
import type {
    GetBroadcastMessagesForMobileResponse_AudioBroadcastForMobile as AudioBroadcast,
    GetBroadcastMessagesForMobileResponse_VideoBroadcastForMobile as VideoBroadcast,
} from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';

const { $broadcastsHelper } = useNuxtApp();
const user = currentUser();

// 2. defineEmits
const $emit = defineEmits<{
    (e: 'hidden'): void;
}>();

// 3. defineProps
// const $props = withDefaults(defineProps<{
//     id: number,
//     optional?: string,
// }>(), { optional: undefined });

// 4. fetchers

// 5. state variables
const componentState = reactive({
    show: false,
    selectedVideoIndex: null as number | null,
});

// 6. computed
const audioBroadcasts = computed(() => user.pinInfo?.broadcasts.audioBroadcasts?.filter(x => !$broadcastsHelper.isAcknowledged(x.id!)) ?? []);
const videoBroadcasts = computed(() => user.pinInfo?.broadcasts.videoBroadcasts?.filter(x => x.required && !$broadcastsHelper.isAcknowledged(x.id!, x.requiredIntervalDays)) ?? []);
const allAcknowledged = computed(() => !user.pinInfo ? null : audioBroadcasts.value.length === 0 && videoBroadcasts.value.length === 0);

// 7. lifecycle hooks

// 9. watches
watch(() => allAcknowledged.value, (allAcknowledged) => {
    if (allAcknowledged === false) {
        nextTick(() => {
            componentState.show = true;
        });
    }
});

// 10. functions
async function onAcknowledgeClick(broadcast: AudioBroadcast) {
    await $broadcastsHelper.onAudioAcknowledge(broadcast);
}

function onClickVideo(index: number) {
    componentState.selectedVideoIndex = index;
}

async function onVideoCompletion(broadcast: VideoBroadcast) {
    await $broadcastsHelper.onVideoAcknowledge(broadcast);
}

function onVideoHide() {
    componentState.selectedVideoIndex = null;
    componentState.show = true;
}

function onOkClick() {
    componentState.show = false;
    $emit('hidden');
}
</script>

<template>
    <div>
        <BModal
            v-if="user.pinInfo?.clockLast?.type === 'In'"
            :model-value="componentState.show && componentState.selectedVideoIndex === null"
            title="Broadcasts"
            title-tag="h2"
            title-class="h4"
            :hide-header-close="allAcknowledged !== true"
            :hide-footer="allAcknowledged !== true"
            ok-variant="outline-dark"
            ok-only
            @hide.prevent
            @ok="onOkClick"
        >
            <div v-if="allAcknowledged === true" class="d-flex align-items-center">
                <div class="bg-success text-white rounded-circle p-2 me-3 fs-1">
                    <ImageIcon icon="broadcast" is-active />
                </div>
                <div>Well done! You've completed all the items on your list.</div>
            </div>
            <div v-else class="d-flex flex-column gap-3">
                <div v-if="audioBroadcasts.length > 0" class="d-flex flex-column gap-3">
                    <div>
                        Please acknowledge that you have read <strong>all the messages</strong> before moving forward.
                    </div>
                    <div
                        v-for="broadcast in audioBroadcasts"
                        :key="broadcast.id"
                    >
                        <BroadcastAudio
                            :model-value="broadcast"
                            :acknowledged="$broadcastsHelper.isAcknowledged(broadcast.id!)"
                            @acknowledge="onAcknowledgeClick(broadcast)"
                        />
                    </div>
                </div>
                <div v-if="videoBroadcasts.length > 0" class="d-flex flex-column gap-3">
                    <div>
                        Before you proceed, please watch all the <strong>required</strong> videos in full.
                    </div>
                    <div
                        v-for="video, videoIndex in videoBroadcasts"
                        :key="video.id"
                    >
                        <BroadcastVideo
                            :model-value="video"
                            :acknowledged="$broadcastsHelper.isAcknowledged(video.id!, video.requiredIntervalDays)"
                            @play="onClickVideo(videoIndex)"
                        />
                    </div>
                </div>
            </div>
        </BModal>
        <VideoPlayer
            v-if="videoBroadcasts"
            v-model="componentState.selectedVideoIndex"
            :videos="videoBroadcasts"
            :ivr-call-id="undefined"
            @complete="onVideoCompletion(videoBroadcasts[componentState.selectedVideoIndex ?? 0])"
            @hide="onVideoHide"
        />
    </div>
</template>
