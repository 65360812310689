<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import type { IconType } from '../ImageIcon.vue';

const route = useRoute();

const $props = withDefaults(defineProps<{
    to?: RouteLocationRaw;
    icon: IconType;
}>(), { to: undefined });

const isActive = computed(() => route.path === $props.to);
</script>

<template>
    <NuxtLink :to="$props.to" :class="{ active: isActive }">
        <ImageIcon :icon="$props.icon" :is-active="isActive" />
        <!-- <img :src="`/icons/${$props.icon}${isActive ? '-active' : ''}.svg`"> -->
        <div class="text"><slot></slot></div>
    </NuxtLink>
</template>

<style scoped lang="scss">
a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--menu-button-color);
    text-decoration: none;
    gap: 4px;
    padding: 2px 0;

    &.active,
    &:hover {
        color: var(--flavor-text);
    }

    ::v-deep(.image-icon) {
        --size: 1.5rem;
    }
}
</style>
