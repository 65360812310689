<script setup lang="ts">
const user = currentUser();
const breakInfo = userBreaks();
const { $flavorManager, $settings } = useNuxtApp();
const logo = $flavorManager.headerLogo();

const componentState = reactive({
    showLogoutModal: false,
    showContactsModal: false,
    showTakeoverNotAcceptedModal: false,
});

const hasContacts = computed(() => user.pinInfo?.customerId === 1 || user.pinInfo?.customerId === 4);

async function onLogout() {
    await user.deauthenticate();
}
</script>

<template>
    <div id="viewport-anchor" class="d-flex flex-column body-container">
        <BreakTimer v-if="breakInfo.isBreakActive" />
        <header class="main-header">
            <div class="header-left"></div>
            <img :src="logo" alt="">
            <div class="header-right">
                <BDropdown no-caret class="config-button">
                    <template #button-content>
                        <FontAwesomeIcon icon="gear" />
                        <span class="visually-hidden">Menu</span>
                    </template>
                    <li role="presentation"><TestGpsButton class="dropdown-item" role="menuitem" /></li>
                    <BDropdownDivider />
                    <BDropdownItem v-if="hasContacts" @click="componentState.showContactsModal = true">Contacts</BDropdownItem>
                    <BDropdownDivider v-if="hasContacts" />
                    <li v-if="!$settings.isMsp" role="presentation"><TakeoverButton class="dropdown-item" role="menuitem" /></li>
                    <BDropdownDivider v-if="!$settings.isMsp" />
                    <BDropdownItem @click="componentState.showLogoutModal = true">Sign Out</BDropdownItem>
                </BDropdown>
            </div>
        </header>
        <main class="main-layout">
            <slot></slot>
            <ToastList />
        </main>
        <MenuBar />
        <BModal
            v-model="componentState.showLogoutModal"
            title="Sign Out"
            title-tag="h2"
            title-class="h4"
            ok-title="Continue"
            cancel-variant="outline-dark"
            @ok="onLogout"
            @hide="componentState.showLogoutModal = false"
        >
            <div>
                Are you sure you want to Sign Out?<br>
                You'll need to sign in again with a PIN.
            </div>
        </BModal>
        <BModal
            v-model="componentState.showContactsModal"
            title="Contacts"
            title-tag="h2"
            title-class="h4"
            hide-footer
        >
            <div>
                Protos Service Support
                <div class="p-3 pb-2">
                    <a href="tel:+1-866-403-9630,12037" class="btn btn-outline-dark w-100">
                        <FontAwesomeIcon icon="phone" class="me-2" />866.403.9630 ext 12037
                    </a>
                </div>
                <div class="p-3">
                    <a href="mailto:helpdesk@protossecurity.com" class="btn btn-outline-dark w-100">
                        <FontAwesomeIcon icon="envelope" class="me-2" />
                        helpdesk@protossecurity.com
                    </a>
                </div>
            </div>
        </BModal>
        <BroadcastModal />
    </div>
</template>

<style lang="scss" scoped>
.main-layout {
    flex: 1 0 0;
    padding: 1rem;
    position: relative;
    overflow-y: auto;
}
</style>

<style lang="scss">
.config-button {
    button.dropdown-toggle {
        color: var(--menu-button-color);
        background-color: var(--bars-color);
        border-color: var(--bars-color);

        &.show {
            color: var(--flavor-text);
        }
    }
}
</style>
