<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import type { Break } from '~/types/BreakTypes';
import type { ClockingStatusIndication, ClockModalProps } from '~/types/Clocking';

const userInfo = currentUser();
const breakInfo = userBreaks();
const circumference = 2 * Math.PI * 90;
const sliderVariant = 'end-break';

const componentState = reactive({
    punchRequest: undefined as ClockModalProps | undefined,
    durationSeconds: 0,
    elapsedSeconds: 0,
    timerInterval: null as NodeJS.Timeout | null,
    currentBreak: null as Break | null,
    clockingStatus: null as ClockingStatusIndication | null,
});

function endBreak() {
    componentState.punchRequest = {
        clockType: 'In',
        isStatusCheck: false,
        workOrderPin: userInfo.pinInfo?.workOrderPin ?? '',
        sessionIds: undefined,
        sessionUuid: userInfo.uuid,
    };

    if (componentState.currentBreak) {
        componentState.clockingStatus = 'processing';
        breakInfo.endBreak(componentState.currentBreak.id);
    }
}

function startTimer() {
    componentState.timerInterval = setInterval(() => {
        componentState.elapsedSeconds = dayjs().utc().diff(dayjs(componentState.currentBreak?.startTimeUtc), 'seconds');
    }, 1000);
}

function stopTimer() {
    if (componentState.timerInterval) {
        clearInterval(componentState.timerInterval);
    }
}

function formatTime(secondsTotal: number) {
    const minutes = Math.floor(Math.abs(secondsTotal) / 60);
    const seconds = Math.abs(secondsTotal) % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

onMounted(() => {
    const currentBreak = breakInfo.breaks.find(b => b.status === 'started');

    if (currentBreak) {
        componentState.durationSeconds = currentBreak.lengthMinutes * 60;
        componentState.elapsedSeconds = dayjs().utc().diff(dayjs(componentState.currentBreak?.startTimeUtc), 'seconds');
        componentState.currentBreak = currentBreak;
        startTimer();
    }
});

onUnmounted(() => {
    stopTimer();
});

const remainingTime = computed(() => {
    return componentState.durationSeconds - componentState.elapsedSeconds;
});

const strokeDashoffset = computed(() => {
    if (!componentState.currentBreak) {
        return circumference;
    }
    if (remainingTime.value <= 0) {
        return 0;
    }

    return circumference - (componentState.elapsedSeconds / componentState.durationSeconds) * circumference;
});

const remainingText = computed(() => {
    return componentState.elapsedSeconds >= componentState.durationSeconds
        ? formatTime(componentState.elapsedSeconds - componentState.durationSeconds)
        : formatTime(remainingTime.value);
});

const timerDescriptorText = computed(() => {
    return remainingTime.value < 0 ? 'exceeded' : 'remaining';
});

const breakLengthText = computed(() => {
    if (!componentState.currentBreak) {
        return '';
    }
    return `${componentState.currentBreak?.lengthMinutes} mins`;
});

const breakNameText = computed(() => {
    if (!componentState.currentBreak) {
        return '';
    }
    return componentState.currentBreak.breakType === 'meal' ? 'Meal Break' : 'Rest Break';
});
</script>

<template>
    <div id="break-timer-overlay">
        <div
            id="break-timer-container"
            :class="{ alert: remainingTime <= 0 }"
            class="d-flex flex-column align-items-center justify-content-center"
        >
            <div id="break-timer-header">
                <span id="break-started-title">Break Started</span>
                <span id="break-name">{{ breakNameText }}</span>
                <span id="break-length">{{ breakLengthText }}</span>
            </div>

            <div class="timer-container">
                <svg width="200" height="200">
                    <circle
                        class="timer-base"
                        cx="100"
                        cy="100"
                        r="90"
                    />
                    <circle
                        class="timer-progress"
                        :class="{ glow: remainingTime <= 0 }"
                        cx="100"
                        cy="100"
                        r="90"
                        :stroke-dasharray="circumference"
                        :stroke-dashoffset="strokeDashoffset"
                    />
                </svg>
                <div id="break-time-info-container">
                    <span id="remaining-exceeded-time">{{ remainingText }}</span>
                    <span id="remaining-exceeded-text">{{ timerDescriptorText }}</span>
                </div>
            </div>
            <div v-if="remainingTime <= 0" id="break-exceeded-alert">
                <img
                    id="break-exceeded-alert-icon"
                    src="/icons/warning-white.svg"
                    alt="Break Icon"
                >
                <div id="break-exceeded-alert-text">Your break time is over and you must clock back in immediately</div>
            </div>
        </div>
        <PunchSlider
            :status="componentState.clockingStatus"
            :variant="sliderVariant"
            @activate="endBreak"
        />
    </div>
</template>

<style scoped lang="scss">
@use "sass:color";

@import "@/assets/styles/variables";

#break-timer-overlay {
    background-color: rgba(0 0 0 / 50%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(0.2em);

    #break-timer-container {
        background-color: #bc5a00;
        border-radius: 10px;
        width: 100%;
        margin: 1.5em;
        padding: 1.5em 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;

        &.alert {
            background-color: $red;
        }

        #break-timer-header {
            display: flex;
            flex-direction: column;
            gap: 0.3em;
            align-items: center;

            #break-started-title {
                font-size: 20px;
                color: white;
                line-height: 24px;
            }

            #break-name {
                font-size: 14px;
                color: white;
                line-height: 20px;
            }

            #break-length {
                font-size: 12px;
                color: white;
                line-height: 16px;
            }
        }

        .timer-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            #break-time-info-container {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                #remaining-exceeded-time {
                    font-size: 32px;
                    color: white;
                    line-height: 28px;
                }

                #remaining-exceeded-text {
                    font-size: 14px;
                    color: white;
                    line-height: 20px;
                }
            }

            .timer-base {
                padding: 0.5em;
                fill: none;
                stroke: #eee;
                stroke-width: 1px;
                stroke-dasharray: 16, 16;
                stroke-linecap: round;
                stroke-opacity: 0.5;
            }

            .timer-progress {
                padding: 0.5em;
                fill: none;
                stroke: white;
                stroke-width: 5px;
                stroke-linecap: round;
                transform-origin: center;
                transform: rotate(-90deg);
                transition: stroke-dashoffset 0.5s linear;

                &.glow {
                    filter: drop-shadow(0 0 0.3rem #ffffff84);
                }
            }
        }

        #break-exceeded-alert {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5em;

            #break-exceeded-alert-icon {
                width: 2.5em;
                height: 2.5em;
            }

            #break-exceeded-alert-text {
                font-size: 14px;
                color: white;
                line-height: 20px;
            }
        }
    }
}
</style>
