<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
const { $settings } = useNuxtApp();

const permissionInfo = incidentPermissions();
onBeforeMount(() => {
    if ($settings.tenant) {
        permissionInfo.loadPermissions();
    }
});

watch(() => $settings.tenant, (tenant) => {
    if (tenant) {
        nextTick(permissionInfo.loadPermissions);
    }
});
</script>

<template>
    <div class="menu-bar">
        <MenuButton to="/" icon="dashboard">Home</MenuButton>
        <MenuButton
            v-show="permissionInfo.data?.canSubmitIncidents === true"
            to="/incident-report"
            icon="warning"
        >
            Incident
        </MenuButton>
        <MenuButton
            v-show="permissionInfo.data?.hasSupervisorVisits === true"
            to="/supervisor-visit"
            icon="person"
        >
            Supervisor
        </MenuButton>
        <MenuButton to="/broadcasts" icon="broadcast">Broadcasts</MenuButton>
    </div>
</template>

<style scoped lang="scss">
.menu-bar {
    background: var(--bars-color);
    display: flex;
    box-shadow: 0 0 2px 0 rgba(0 0 0 / 16%), 0 2px 2px -8px rgba(0 0 0 / 8%);
    padding: 0.5rem 1rem;

    :deep(> a) {
        flex: 1 1 20%;
    }
}
</style>
