<script setup lang="ts">
const user = currentUser();
const isShowing = ref(false);
const show = ref(false);

async function onClick() {
    // check if clocked in and if they are clocked in, dont allow (show modal)
    if (!isClockedIn()) {
        await user.performTakeover();
    }
    else {
        isShowing.value = true;
        show.value = true;
    }
}

function onHide() {
    show.value = false;
}
function onHidden() {
    isShowing.value = false;
}

function isClockedIn(): boolean | null {
    if (!user.pinInfo?.clockLast?.type) {
        return false;
    }
    if (user.pinInfo.clockLast.type === 'Out' || user.pinInfo.clockLast.isStatusCheck) {
        return false;
    }

    return true;
}
</script>

<template>
    <button type="button" @click.prevent="onClick">
        <slot>Takeover</slot>
        <BModal
            v-if="isShowing"
            v-model="show"
            ok-only
            ok-title="Close"
            ok-variant="outline-dark"
            @hide="onHide"
            @hidden="onHidden"
        >
            <div class="d-flex flex-column gap-2">
                <div class="text-center"><img :src="`/icons/${'error-takeover.svg'}`" alt=""></div>
                <div class="text-center title">Takeover not Accepted</div>
                <div class="text-center">You must be clocked out of your current shift before taking over another shift. Please clock out and try again.</div>
            </div>
        </BModal>
    </button>
</template>

<style lang="scss" scoped>
.title {
    font-size: 16px;
    color: #922c2c;
    font-weight: 700;
}
</style>
