<script setup lang="ts">
import { ToastType } from '~/types/ToastMessage';

const { $toastService } = useNuxtApp();
</script>

<template>
    <div class="toast-list">
        <TransitionGroup name="toasts" tag="div">
            <div
                v-for="toast of $toastService.toasts.value"
                :key="toast.key"
                class="toast"
                :class="toast.type"
                :role="toast.type === ToastType.Error ? 'alert' : 'status'"
                :aria-live="toast.type === ToastType.Error ? 'assertive' : 'polite'"
            >
                <div class="toast-text">
                    <div class="toast-message">
                        {{ toast.message }}
                    </div>
                    <div v-if="toast.details" class="toast-details">
                        {{ toast.details }}
                    </div>
                </div>
                <div v-if="toast.hideClose !== true && !toast.customButtonText" class="toast-action">
                    <button
                        v-if="!toast.customButtonText"
                        aria-label="Dismiss Toast"
                        class="btn btn-flat p-0 m-0"
                        @click="$toastService.dismissToast(toast)"
                    >
                        <FontAwesomeIcon icon="times" />
                    </button>
                    <button
                        v-else
                        class="btn btn-flat p-0 m-0 custom-action"
                        @click="toast.customAction"
                    >
                        {{ toast.customButtonText }}
                    </button>
                </div>
            </div>
        </TransitionGroup>
    </div>
</template>

<style scoped lang="scss">
    .toasts-move,
    .toasts-enter-active,
    .toasts-leave-active {
        transition: all 0.5s ease;
    }

    .toasts-enter-from,
    .toasts-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .toast-list {
        position: absolute;
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        inset: auto 1rem 1rem;
        z-index: 1000;

        .toast {
            min-width: 12rem;
            color: #fff;
            padding: 0.5rem 0.75rem;
            font-size: 0.9rem;
            border-radius: 0.25rem;
            box-shadow: 2px 2px 4px 0 rgba(0 0 0 / 50%);
            display: flex;
            gap: 0.5rem;

            .toast-action {
                flex-grow: 1;
                text-align: end;
                margin-left: 1rem;

                button.btn {
                    color: white;
                }
            }

            .toast-details {
                font-size: 0.8rem;
            }

            &.info {
                background-color: var(--bs-info);
            }

            &.primary {
                background-color: var(--bs-primary);
            }

            &.error {
                background-color: var(--bs-danger);
            }

            &.success {
                background-color: var(--bs-success);
            }

            &.dark {
                background-color: var(--bs-dark);
            }

            &.warning {
                color: black;
                background-color: var(--bs-warning);

                .toast-action {
                    button.btn {
                        color: black;
                    }
                }
            }

            .custom-action {
                font-size: 0.8rem;
                font-weight: 700;
            }
        }
    }
</style>
